<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h3>Buchungsübersicht</h3>
                </div>
            </div>
        </div>
        <div class="blue-bg">
            <div class="container">
                <dl class="row">
                    <dt class="col-lg-3">Gutschein Betrag:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ coupon.couponValue }} €</dd>
                </dl>
                <dl class="row">
                    <dt class="col-lg-3">Lieferadresse:</dt>
                    <dd class="col-lg-6 offset-lg-1" v-if="coupon.deliveryType == 'postal'">

                        <p>{{user_shipping_data.title}} {{user_shipping_data.lastname}} {{user_shipping_data.firstname}}</p>
                        <p>{{user_shipping_data.street}}</p>
                        <p>{{user_shipping_data.zipcode}} {{user_shipping_data.city}}</p>
                        <p>+49 {{user_shipping_data.phone}}</p>
                        <p>{{user_shipping_data.mail}}</p>
                    </dd>
                    <dd class="col-lg-6 offset-lg-1" v-if="coupon.deliveryType == 'pickup'">
                        Selbstabholer
                    </dd>
                </dl>

                <div class="col-12">
                    <a href="coupons" class="button black">Ändern</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'CouponCartDetailsXS',

        components: {
        },
        data() {
            return {
                coupon: this.$store.state.cart,
                user_shipping_data: this.$store.state.user_shipping_data.user_shipping_data,
            }
        },
    }
</script>
<style lang="scss" scoped>

    h3{
        font-family: 'futura-pt-bold';
        font-size:20px;
        letter-spacing: 0.5px;
        line-height: 30px;
    }

    .blue-bg{
        padding-top:35px;
        .container{
            padding-top: 0px;
            padding-left:20px;
            padding-right:20px;

            dt,
            dd{
                font-size: 17px;
                letter-spacing: 0.42px;
                line-height: 24px;
            }

            .button{
                margin-top: 35px;
            }
        }
    }

</styles>